'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider", "appConfig", function($stateProvider, appConfig) {
        $stateProvider
            .state('index.checkout', {
                url: '/checkout?status&ordernumber',
                templateUrl: 'app/checkout/checkout.html',
                controller: 'CheckoutPaymentCtrl'
            });
    }]);